import { createContext, useState, useContext, useEffect, useRef } from 'react'
import { useSession } from 'next-auth/client'
import Cookies from 'universal-cookie'
import { get, post } from 'utils/api'
import { getCartId } from 'utils/helpers'
import shortid from 'shortid'
import { parse } from 'utils/qs'
import { trackProductsListView } from 'utils/analytics/events'
import dayjs from 'dayjs'
import { default as featuresData } from 'utils/data/features'

let timer

const defaultCart = {
    items: [],
    basket_id: null,
    details: {
        account_slug: null,
        has_information: false,
        has_delivery: false,
        information: {},
        delivery: {
            name: '',
            phone: '',
            delivery: ''
        },
        payment: {
            bank: '',
            is_company: 0,
            subscribes: 0,
            agree: 0
        }
    }
}

const StateContext = createContext({
    loading: true,
    hasUser: false,
    cart: null,
    user: null,
    redirecting: false,
    visitorId: null,
    toast: null,
    filter: {},
    likes: {},
    loader: false,
    coupon: null,
    delivery: null,
    features: [],
    couponLoading: null,
    setRedirecting: () => {},
    setCoupon: () => {},
    getCoupon: () => {},
    couponCheck: () => {},
    minimizeCoupon: () => {},
    updateCart: () => {},
    updateApiBasket: () => {},
    showLoader: () => {},
    showToast: () => {},
    hideToast: () => {},
    likeUnlike: () => {},
    addToCart: () => {},
    addToCartItems: () => {},
    clearCurrentCartAccount: () => {},
    removeFromCart: () => {},
    changeCartQuantity: () => {},
    updateCartDetails: () => {},
    submitFilter: () => {},
    resetFilter: () => {},
    setUser: () => {},
    queueProductImpression: () => {},
    selectCheckoutAccount: () => {}
})

export function StateContextProvider({ children }) {
    const [loading, setLoading] = useState(true)
    const [redirecting, setRedirecting] = useState()
    const [filter, setFilter] = useState({ group: [], price: null, like: null })
    const [cart, setCart] = useState(defaultCart)
    const [toast, setToast] = useState(null)
    const [user, setUser] = useState(null)
    const [hasUser, setHasUser] = useState(false)
    const [likes, setLikes] = useState([])
    const [loader, showLoader] = useState(false)
    const [features] = useState(featuresData)
    const [delivery, setDelivery] = useState('lt')
    const [visitorId, setVisitorId] = useState()
    const cookies = new Cookies()
    const cookie = cookies.getAll()
    const [session, sessionLoading] = useSession()
    const [productQueue, setProductQueue] = useState([])
    const queueTimeout = useRef(null)
    const [coupon, setCoupon] = useState(null)
    const [couponLoading, setCouponLoading] = useState(true)

    useEffect(() => {
        if (cookie.hasUser) {
            setHasUser(cookie.hasUser)
        }

        // coupon
        const query = parse(window.location.search.split('?')[1])
        let couponCode = query.coupon

        // fource Coupon code
        // if (!coupon && !query.coupon) {
        //     couponCode = 'JUODASIS50'
        // }

        if (couponCode && couponCode !== coupon) {
            getCoupon(couponCode)
        } else if (cookie.coupon) {
            if (dayjs(cookie.coupon.end).valueOf() > dayjs().valueOf()) {
                getCoupon(cookie.coupon.code)
            } else {
                setCouponLoading(false)
            }
        } else {
            setCouponLoading(false)
        }

        // cookie
        if (cookie.delivery) {
            setDelivery(cookie.delivery)
        }

        // cart
        if (typeof localStorage != 'undefined' && typeof localStorage.getItem != 'undefined') {
            const cart = localStorage.getItem('cart')
            if (cart) {
                const data = JSON.parse(cart)

                if (data && data.items && data.items[0] && typeof data.items[0].single_price === 'undefined') {
                    localStorage.removeItem('cart')
                }

                setCart(data)
            }
        }

        // getFeatures()
    }, [])

    useEffect(() => {
        if (!sessionLoading) {
            if (session && session.user) {
                const n = session.user.account_id ? 2 : 1
                setAppCookie('hasUser', n)
                setHasUser(n)
            } else {
                setHasUser(false)
                cookies.remove('hasUser')
            }
        }
    }, [session, sessionLoading])

    useEffect(() => {
        if (!sessionLoading) {
            let visitorId

            // likes and token
            if (typeof window != 'undefined' && session && session.user) {
                const { user } = session
                window.token = session.token
                setLikes(user.likes)
                setUser({
                    ...user,
                    token: session.token
                })
                visitorId = user.visitor_id
            }

            // visitorId
            if (!visitorId) {
                visitorId = cookie.visitor
            }
            if (!visitorId) {
                visitorId = `${shortid.generate()}-${new Date().getTime()}`
                setAppCookie('visitor', visitorId)
            }

            setVisitorId(visitorId)
            // window.aa('setUserToken', visitorId)
            window.visitorId = visitorId

            // filter
            setFilter(cookie.filter)

            setLoading(false)
        }
    }, [sessionLoading])

    // features
    // const getFeatures = async () => {
    //     const results = await get('features')
    //     setFeatures(results.data.data.features)
    // }

    // delivery
    function changeDelivery(value) {
        setDelivery(value)
        setAppCookie('delivery', value)
    }

    // cart

    function addToCartItems(newItems) {
        const items = cart.items.filter(i => {
            for (var a = 0; a < newItems.length; a++) {
                const item = newItems[a]
                if (i.id === item.id) {
                    const iOptions = i.data.options
                    const itemOptions = item.data.options
                    if (!iOptions && !itemOptions) {
                        return false
                    }
                    if (iOptions && itemOptions) {
                        return !(JSON.stringify(iOptions) === JSON.stringify(itemOptions))
                    }
                }
            }

            return true
        })

        updateCartItems([...items, ...newItems])
        updateApiBasket([...items, ...newItems])
    }

    function addToCart(item) {
        const items = cart.items.filter(i => {
            if (i.id === item.id) {
                const iOptions = i.data.options
                const itemOptions = item.data.options
                if (!iOptions && !itemOptions) {
                    return false
                }
                if (iOptions && itemOptions) {
                    return !(JSON.stringify(iOptions) === JSON.stringify(itemOptions))
                }
            }
            return true
        })

        updateCartItems([...items, item])
        updateApiBasket([...items, item])
    }

    async function updateApiBasket(items, email, updateState = true) {
        const response = await post('update-basket', { basket_id: cart.basket_id, items, email })
        if (response.data && response.data.data) {
            const { basket_id } = response.data.data
            if (updateState) {
                updateCart({ ...cart, items, basket_id })
            }
        }
    }

    function removeFromCart(cartId) {
        updateCartItems([...cart.items].filter(product => getCartId(product) != cartId))
    }

    function changeCartQuantity(cartId, value) {
        const items = [...cart.items].map(product => {
            const prodCartId = getCartId(product)
            if (prodCartId == cartId) {
                product.data.quantity = value
            }
            return product
        })
        updateCartItems(items)
    }

    function updateCartItems(items) {
        updateCart({ ...cart, items })
    }

    function selectCheckoutAccount(account_slug) {
        updateCartDetails({ ...cart.details, delivery: { ...cart.details.delivery, delivery: '' }, account_slug })
    }

    function updateCartDetails(details) {
        updateCart({ ...cart, details })
    }

    function updateCart(cart) {
        setCart(cart)
        if (typeof localStorage != 'undefined' && typeof localStorage.getItem != 'undefined') {
            localStorage.setItem('cart', JSON.stringify(cart))
        }
    }

    function clearCurrentCartAccount() {
        const slug = cart.details.account_slug
        if (!slug) return

        updateCart({
            items: [...cart.items].filter(product => product.account_slug != slug),
            details: {
                ...cart.details,
                account_slug: null,
                delivery: {
                    name: cart.details.delivery.name,
                    phone: cart.details.delivery.phone,
                    delivery: ''
                }
            }
        })
    }

    // filter

    function submitFilter(data) {
        setFilter(data)
        setAppCookie('filter', data)
    }

    function resetFilter() {
        setFilter({ group: [], price: null, like: null })
        setAppCookie('filter', null)
    }

    function setAppCookie(name, value) {
        cookies.set(name, value, { path: '/', expires: new Date(Date.now() + 315360000000) })
    }

    function likeUnlike(productId) {
        const like = likes.indexOf(productId) === -1 ? 1 : 0
        get(`like-unlike/${productId}/${like}`)
        if (like) {
            setLikes([...likes, productId])
        } else {
            setLikes(likes.filter(id => id != productId))
        }
    }

    // toast

    function showToast(message, type = 'info') {
        if (!message) return

        setToast({ message, type })
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            hideToast()
        }, 4000)
    }

    function hideToast() {
        setToast(null)
    }

    // impressions
    function queueProductImpression(product) {
        const find = productQueue.find(prod => prod.code === product.code)
        if (!find) {
            const products = [...productQueue, product]
            setProductQueue(products)
            if (queueTimeout.current) {
                clearTimeout(queueTimeout.current)
            }
            queueTimeout.current = setTimeout(() => {
                if (products.length) {
                    trackProductsListView(products)
                }
                setProductQueue([])
            }, 500)
        }
    }

    // coupon
    const getCoupon = async (code, cancelMinimized = false, valid = true) => {
        const response = await get(`coupon/${code}`)
        if (response && response.data && response.data.data && response.data.data.end) {
            if (!valid) {
                setCouponLoading(false)
                return 1
            }
            const data = {
                code: code,
                max: response.data.data.max,
                end: response.data.data.end,
                account_slug: response.data.data.account_slug,
                seconds: response.data.data.seconds
            }
            let minimized = cookie.coupon_minimized == 1 ? 1 : 0
            if (cancelMinimized) {
                minimized = 0
                cookies.set('coupon_minimized', minimized ? 1 : 0, {
                    path: '/',
                    expires: new Date(Date.now() + 86400000)
                })
            }
            setCoupon({ ...data, minimized })
            if (!cookie.coupon || cookie.coupon.code != code) {
                cookies.set('coupon', data, {
                    path: '/',
                    expires: new Date(Date.now() + response.data.data.seconds * 1000)
                })
            }
            setCouponLoading(false)
            return 1
        } else {
            setCoupon(null)
            setCouponLoading(false)
            cookies.remove('coupon')
            return 0
        }
    }

    const minimizeCoupon = minimized => {
        setCoupon({ ...coupon, minimized: minimized ? 1 : 0 })
        cookies.set('coupon_minimized', minimized ? 1 : 0, { path: '/', expires: new Date(Date.now() + 86400000) })
    }

    const couponCheck = product => {
        let {
            old_price_preview,
            single_price,
            price_preview,
            simple_price,
            price,
            account_discount,
            account_slug,
            full_single_price,
            price_from
        } = product
        if (coupon && account_discount && (!coupon.account_slug || coupon.account_slug === account_slug)) {
            const use_discount = coupon.account_slug
                ? coupon.max
                : coupon.max < account_discount
                ? coupon.max
                : account_discount

            if (simple_price || !price_preview) {
                // alert(JSON.stringify([single_price, price, price_from]))
                const use_price = parseFloat(full_single_price ? full_single_price : (single_price ? single_price : price ? price : price_from))
                old_price_preview = `${use_price.toFixed(2)}€`
                single_price = Math.round(use_price * (1 - use_discount / 100) * 100) / 100
                price_preview = `${single_price.toFixed(2)}€`
                price = single_price

            } else {
                old_price_preview = price_preview
                const parts = price_preview.split(' - ')
                if (parts.length === 1) {
                    parts.push(parts[0])
                }
                const from = Math.round(parseFloat(parts[0]) * (1 - use_discount / 100) * 100) / 100
                const to = Math.round(parseFloat(parts[1]) * (1 - use_discount / 100) * 100) / 100
                price_preview = `${from.toFixed(2)}€ - ${to.toFixed(2)}€`
            }
        }

        if (parseFloat(product.price) < price) {
            return { ...product }
        }

        return { ...product, old_price_preview, single_price, price, price_preview, simple_price }
    }

    const context = {
        delivery,
        features,
        changeDelivery,
        minimizeCoupon,
        getCoupon,
        setCoupon,
        couponLoading,
        couponCheck,
        coupon,
        loader,
        likes,
        loading,
        cart,
        user,
        hasUser,
        visitorId,
        filter,
        toast,
        showLoader,
        redirecting,
        setRedirecting,
        addToCart,
        addToCartItems,
        changeCartQuantity,
        submitFilter,
        removeFromCart,
        clearCurrentCartAccount,
        selectCheckoutAccount,
        updateCartDetails,
        updateApiBasket,
        resetFilter,
        updateCart,
        likeUnlike,
        showToast,
        setUser,
        hideToast,
        queueProductImpression
    }

    return <StateContext.Provider value={context}>{children}</StateContext.Provider>
}

export default function useAppState() {
    return useContext(StateContext)
}
